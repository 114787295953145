<script setup>
/* Imports */
import {
  ref,
  computed,
  inject,
} from 'vue';

/* Components */
import MapNumberOfUpdates from './MapNumberOfUpdates.vue';
import MapPrice from './MapPrice.vue';
import ShopNowProductCardBtn from './ShopNowProductCardBtn.vue';

/* Helpers */
import {
  shouldDisplayBeforePriceAndUp,
  mapGetters,
  mapActions,
} from '../helpers/mainHelpers';

/* Inject */
const translations = inject('translations');
const isWorldwideUpdates = inject('isWorldwideUpdates');
const isHeatmap = inject('isHeatmap');

/* Store - Getters */
const {
  getPartNumber: partNumb,
  getDrawerMode: drawerMode,
  getLocale: locale,
} = mapGetters();

/* Store - Actions */
const {
  removePolygon,
  setPolygon,
  setPartNumber,
  setDrawerMode,
  setIsWorldwideUpdates,
} = mapActions();

/* Props */
const props = defineProps({
  maps: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

/* State */
const state = ref({
  displayBeforePricePriceAndUpText: shouldDisplayBeforePriceAndUp(locale.value),
});

/* Methods */
const switchToggled = (partNumber) => partNumb.value === partNumber;

const showNumberOfUpdates = (partNumber) => switchToggled(partNumber) && !isWorldwideUpdates.value && isHeatmap.value;

// Returns the price with the before and up text || and up text when price has only variation
const hasPriceVariation = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText
      ? `${translations.value.ITFE_MARINE_MAPS_BEFORE_AND_UP} ${product.formattedPrice}`
      : `${product.formattedPrice} ${translations.value.ITFE_MARINE_MAPS_AND_UP}`;
  }
  return product.formattedPrice;
};

// Returns the before and up text when promoted pricing
/* eslint-disable-next-line consistent-return */
const hasBeforeAndUpPromotedPricing = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText ? `${translations.value.ITFE_MARINE_MAPS_BEFORE_AND_UP}` : '';
  }
};

// Returns the and up text when promoted pricing
/* eslint-disable-next-line consistent-return */
const hasAndUpPromotedPricing = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText ? '' : `${translations.value.ITFE_MARINE_MAPS_AND_UP}`;
  }
};

/**
 * Returns the coordinates array for the polygon
 * @param {object} polygon - The polygon object
 * @returns {Array}        - The coordinates array
 */
const getCoordinates = (polygon) => {
  if (polygon.type === 'MultiPolygon') {
    return polygon.multiPolygonCoordinates;
  }
  if (polygon.type === 'Polygon') {
    return polygon.polygonCoordinates;
  }
  return [];
};

/* Handle the drawer mode */
const changeDrawerMode = () => {
  // when user clicks view on map and the drawer is full height,
  // make the drawer half way, so that the user can see the map
  if (drawerMode.value === 'top') {
    setDrawerMode('middle');
  }
};

/**
 * Checks coordinates array to see if any value crosses anti-meridian
 * Returns true if coordinates are good as is
 * Returns false if coordinates need adjusted
 * @param {Array} coordinates - The coordinates array
 * @returns {boolean}         - True if coordinates are good as is, false if coordinates need adjusted
 */
const checkCoordinates = (coordinates) => {
  let valid = false;
  if (coordinates) {
    let longsOverZero = 0;
    let totalLatLongs = 0;
    let maxLongitude = null;
    let minLongitude = null;

    const totalPolygons = coordinates.length;
    for (let i = 0; i < totalPolygons; i += 1) {
      const singlePolygon = coordinates[i];

      // Hardcode [0] since inner array is always at [0]
      const singlePolygonCoordinateCount = singlePolygon[0].length;
      for (let j = 0; j < singlePolygonCoordinateCount; j += 1) {
        const longitude = coordinates[i][0][j][1];

        if (!maxLongitude || maxLongitude < longitude) {
          maxLongitude = longitude;
        }
        if (!minLongitude || minLongitude > longitude) {
          minLongitude = longitude;
        }
        if (longitude >= 0) {
          longsOverZero += 1;
        }
        totalLatLongs += 1;
      }
    }
    const allSameSign = longsOverZero === 0 || longsOverZero === totalLatLongs;

    if (allSameSign) {
      valid = true;
    } else {
      valid = maxLongitude - minLongitude < 180;
    }
  }
  return valid;
};

/**
 * This fixes a known issue with Leaflet when a polygon crosses the anti-meridian.
 * We subtract 360 from any longitude value > 0 to avoid it wrapping around the map.
 * @param {*} coordinates - The coordinates array
 * @returns {Array}       - The new coordinates array
 */
const processCoordinates = (coordinates) => {
  // Create a deep copy of the coordinates array
  const newCoordinates = JSON.parse(JSON.stringify(coordinates));

  const totalPolygons = newCoordinates.length;
  for (let i = 0; i < totalPolygons; i += 1) {
    const singlePolygon = newCoordinates[i];

    // Hardcode [0] since inner array is always at [0]
    const singlePolygonCoordinateCount = singlePolygon[0].length;
    for (let j = 0; j < singlePolygonCoordinateCount; j += 1) {
      const longitude = newCoordinates[i][0][j][1];
      if (longitude > 0) {
        newCoordinates[i][0][j][1] = longitude - 360;
      }
    }
  }

  return newCoordinates;
};

/* Handle the polygon */
const changePolygon = (map) => {
  changeDrawerMode();
  const { polygon } = map;
  const partNumber = map.sku;
  if (partNumber === partNumb.value) {
    removePolygon();
    // Show worldwide updates if toggle is off
    setIsWorldwideUpdates(true);
  } else {
    let coordinates = getCoordinates(polygon);

    // Checks if longitude values cross anti-meridian
    const validCoordinates = checkCoordinates(coordinates);

    // Subtract 360 from longitude values > 0 to fix anti-meridian wrapping
    if (!validCoordinates) {
      coordinates = processCoordinates(coordinates);
    }

    setPolygon({
      dashed: polygon.dashed,
      type: polygon.type,
      coordinates,
    });
    setPartNumber(partNumber);
    // Hide worldwide updates if toggle is on
    setIsWorldwideUpdates(false);
  }
};

const usePricingProxyServices = computed(() => window?.AppData?.contentfulEnvSettings?.ITFE_MARINE_MAPS_ENABLE_PRICING_PROXY_SERVICES);
</script>

<template>
  <div
    v-if="!props.isLoading"
    class="result-block__wrap"
  >
    <div
      v-for="(map, index) in props.maps"
      :key="index"
      class="result-block"
      data-testid="result-block"
    >
      <div
        class="result-block__info"
        @click.prevent="changePolygon(map)"
        @keyup.enter.prevent="changePolygon(map)"
      >
        <g-heading
          class="result-block__info__title"
          :content="map?.name"
          heading-size="3"
        />
        <div
          :class="{'result-block__price-switch-wrap': !usePricingProxyServices}"
        >
          <MapPrice
            v-if="usePricingProxyServices"
            :map="map"
          />
          <!-- TODO: Remove this after ITFE_MARINE_MAPS_ENABLE_PRICING_PROXY_SERVICES feature flag is removed. -->
          <template v-else>
            <p
              v-if="map.formattedPrice?.length"
              class="result-block__info__buy__price"
            >
              <g-price
                v-if="map.formattedSalePrice"
                :original-price="map.formattedListPrice"
                :discounted-price="map.formattedSalePrice"
                has-text="true"
                :and-up-text="hasAndUpPromotedPricing(map)"
                :from-text="hasBeforeAndUpPromotedPricing(map)"
              />
              <g-copy
                v-else
                type="normal"
              >
                {{ hasPriceVariation(map) }}
              </g-copy>
            </p>
          </template>
          <g-switch
            class="result-block__switch"
            size="large"
            :right-label="translations.ITFE_MARINE_MAPS_VIEW_ON_MAP"
            tabindex="0"
          >
            <input
              type="checkbox"
              name="resultSwitch"
              :checked="switchToggled(map.sku)"
              @update="changePolygon(map)"
            >
          </g-switch>
        </div>
        <MapNumberOfUpdates
          v-if="showNumberOfUpdates(map.sku)"
          class="result-block__number-of-updates"
          :map-multi-polygon-coordinates="map.polygon?.multiPolygonCoordinates"
        />
      </div>
      <ShopNowProductCardBtn
        :map="map"
        :index="index"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.result-block__wrap {
  display: flex;
  padding: 0 1.25rem;
  gap: 1.25rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin: 0 -1.25rem;

  @include breakpoint('sm') {
    display: revert;
    padding: revert;
    gap: revert;
    overflow-x: revert;
    scroll-snap-type: revert;
    margin: revert;
  }
}

.result-block {
  @include make-drop-shadow();
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: $color-white;
  margin-bottom: 1rem;
  min-width: 80vw;
  scroll-snap-align: center;

  @include breakpoint('sm') {
    min-width: revert;
    scroll-snap-align: revert;

    &:first-child {
      margin-top: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &__title {
      color: $color-black;

      :deep(h3) {
        line-height: 1.25;
        margin-top: 0;
      }
    }

    &__buy {
      width: 100%;
      align-items: top;
      justify-content: space-between;

      &__price {
        @include font-primary();
        color: $color-black;
        font-size: 1.125rem;
        @include font-primary-weight-medium();
        padding: 0;
        min-width: 30px;
        margin: 0;
      }
    }

    &__switch {
      margin: 1rem 0;
    }
  }

  &__price-switch-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;

    @include breakpoint('md') {
      flex-flow: row wrap;
      align-items: flex-start;
    }

    :deep(.g__copy) {
      margin-bottom: 0;
    }
  }

  &__switch {
    margin-bottom: 1rem;
  }

  &__number-of-updates {
    border-top: 2px solid $color-gray-85;
    padding-top: 1rem;
  }

  // font-size for the switch label
  :deep(.g__switch__label__text--right) {
    font-size: 0.875rem;
    @include font-primary-weight-medium();
  }

  /**
  * g-switch -> Global Component
  * Prevent the input from breaking the screen
  * when the user navigates through cards by pressing tab
  */
  .g__switch input {
    display: inline-block;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    visibility: hidden;
  }
}
</style>
