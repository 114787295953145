<script setup>
/* Imports */
import {
  computed,
  inject,
  ref,
} from 'vue';

import { useScreenWidth } from '../composables/useScreenWidth';

/* Initialize composables */
const { isTablet } = useScreenWidth();

/* Inject */
const locale = inject('locale') || ref('en-US');
const lastViewedMapBrand = inject('lastViewedMapBrand');
const translations = inject('translations');
const subdirectory = inject('subdirectory') || ref('marine');

/* Marine Maps URL */
const marineMapsUrl = (mapType) => {
  const {
    pathName = `/${locale.value}/${subdirectory.value}/`,
    search: existingSearchParams,
  } = window?.location || {};
  const searchParams = new URLSearchParams(existingSearchParams);

  if (searchParams.has('maps')) {
    searchParams.delete('maps');
  }

  if (searchParams.has('overlay')) {
    searchParams.delete('overlay');
  }

  searchParams.set('maps', mapType);
  searchParams.set('overlay', 'false');

  return `${pathName}?${searchParams.toString()}`;
};

/* Save selected map brand to local storage */
const saveMapBrandToLocalStorage = (mapType) => {
  lastViewedMapBrand.value = mapType;
};

/* Toggle Map Brand -> Save selected map brand to local storage and update URL */
const toggleMapBrand = (mapType) => {
  saveMapBrandToLocalStorage(mapType);
  window.location.href = marineMapsUrl(mapType);
};

/* Computed - Cards Data */
const cards = computed(() => [
  {
    id: 'garmin',
    href: '',
    copy: {
      content: translations.value?.ITFE_MARINE_MAPS_LEFT_CARD_CONTENT,
      textAlign: 'left',
    },
    heading: {
      content: translations.value?.ITFE_MARINE_MAPS_LEFT_CARD_HEADING,
      size: '2',
      styleObject: {
        textTransform: 'uppercase',
        textAlign: 'left',
      },
    },
    ctas: [
      {
        text: translations.value?.ITFE_MARINE_MAPS_OVERLAY_BUTTON,
        theme: 'candy-blue',
        size: 'large',
        href: marineMapsUrl('garmin'),
      },
    ],
  },
  {
    id: 'another-brand',
    copy: {
      content: translations.value?.ITFE_MARINE_MAPS_RIGHT_CARD_CONTENT,
      textAlign: 'left',
    },
    heading: {
      content: translations.value?.ITFE_MARINE_MAPS_RIGHT_CARD_HEADING,
      size: '2',
      styleObject: {
        textTransform: 'uppercase',
        textAlign: 'left',
      },
    },
    ctas: [
      {
        text: translations.value?.ITFE_MARINE_MAPS_OVERLAY_BUTTON,
        theme: 'candy-blue',
        size: 'large',
        href: marineMapsUrl('another-brand'),
      },
    ],
  },
]);

/* Computed - Card Width */
const cardWidth = computed(() => (isTablet.value ? 'half' : 'fourth'));

/* Computed - Background Image */
const computedBackgroundImage = computed(() => `url(${translations.value.ITFE_MARINE_MAPS_OVERLAY_BACKGROUND_IMAGE || ''})`);
</script>

<template>
  <div
    class="interstitial"
    data-testid="interstitial"
  >
    <g-heading
      class="interstitial__heading"
      :content="translations.ITFE_MARINE_MAPS_OVERLAY_HEADING"
      heading-size="1"
      text-align="center"
    />
    <div class="interstitial__card-container">
      <g-card
        v-for="card in cards"
        :key="card.id"
        data-testid="interstitial-card"
        class="interstitial__card-container__card"
        .card="card"
        :width="cardWidth"
        @click="toggleMapBrand(card.id)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.interstitial {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* stylelint-disable-next-line value-keyword-case */
  background-image: v-bind(computedBackgroundImage);
  background-repeat: no-repeat;
  background-size: cover;

  &__heading {
    margin-top: 1rem;
    text-transform: uppercase;
  }

  &__card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0.25rem 2.5rem;
    margin-top: 1rem;
    gap: 2rem;

    @include breakpoint('sm') {
      padding: 0 2rem;
    }

    @include breakpoint('lg') {
      padding: 0;
    }

    :deep(.g__card__content) {
      padding: 0.75rem;
    }

    :deep(.g__button) {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
